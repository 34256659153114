import { useEffect } from 'react';

import { useGetCurrentUserQuery } from '../api/userSlice';
import {
  setBaseUserInfo,
  setBaseUserInfoIsLoading,
} from '../storeSlices/userSlice';
import { useAppDispatch, useAppSelector } from './reduxStoreHooks';
import useSetAppLanguage from './useSetAppLanguage';

const useFetchAndSetBaseUserInfo = () => {
  const dispatch = useAppDispatch();
  const storeBaseUserInfo = useAppSelector((state) => state.user.baseUserInfo);
  const skipQuery = !!storeBaseUserInfo;

  const { data: baseUser, isLoading } = useGetCurrentUserQuery(undefined, {
    skip: skipQuery,
  });

  const setAppLanguage = useSetAppLanguage();

  useEffect(() => {
    dispatch(setBaseUserInfoIsLoading(isLoading));

    if (baseUser) {
      dispatch(setBaseUserInfo(baseUser));
      setAppLanguage(baseUser.userLanguage);
      dispatch(setBaseUserInfoIsLoading(false));
    }
    // if we put setAppLanguage in the dependency array, it will cause an infinite loop BUT NOT IN LOCAL, ONLY IN DEV ENV (don't know about UAT or PROD)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, baseUser, dispatch]);
};

export default useFetchAndSetBaseUserInfo;
