import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import palette from './palette';
import shadows, { customShadows } from './shadows';
import { typography } from './typography';

import { Autocomplete } from './overrides/Autocomplete';
import { Button } from './overrides/Button';
import { Card } from './overrides/Card';
import { DataGrid } from './overrides/DataGrid';
import { Dialog } from './overrides/Dialog';
import { ListItem } from './overrides/ListItem';
import { Select } from './overrides/Select';
import { Tooltip } from './overrides/Tooltip';

type Props = {
  children: ReactNode;
};

export default function AppTheme({ children }: Props) {
  const theme = useMemo(() => {
    return createTheme({
      cssVariables: {
        colorSchemeSelector: 'data-mui-color-scheme',
        cssVarPrefix: 'template',
      },
      typography,
      shadows: shadows.light,
      palette: palette.light,
      customShadows: customShadows.light,
      shape: {
        borderRadius: 8,
      },
      components: {
        ...ListItem,
        ...Dialog,
        ...Card,
        ...Button,
        ...DataGrid,
        ...Tooltip,
        ...Select,
        ...Autocomplete,
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      {children}
    </ThemeProvider>
  );
}
