import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const Autocomplete: Components<Theme> = {
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: React.createElement(ExpandMoreIcon),
    },
    styleOverrides: {
      root: {
        fontWeight: 'bold',
      },
    },
  },
};
