import { api } from '.';
import {
  BaseUserInfo,
  DeleteUserRequest,
  EditChildUserData,
  EditUserRequest,
  ResetPasswordRequest,
  UnsavedUserAccount,
  UserAccount,
  UserForTable,
} from '../types/userAccounts';

const usersUrl = '/UserAccount';

const userApiEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<BaseUserInfo, void>({
      query: () => ({
        url: `${usersUrl}/currentUser`,
      }),
      providesTags: ['User'],
    }),
    getUserDataWithPermissionsForSelectedCompany: build.query<
      UserAccount,
      { companyId: number; parentCompanyId: number; accountId: number }
    >({
      query: ({ companyId, parentCompanyId, accountId }) => ({
        url: `${usersUrl}/${companyId}/${parentCompanyId}/${accountId}`,
      }),
      providesTags: ['User'],
    }),
    getCurrentUserChildren: build.query<
      UserForTable[],
      {
        companyId: number;
        supplyChainId: string;
        accountId: number;
        year: number;
      }
    >({
      query: ({ companyId, supplyChainId, accountId, year }) => ({
        url: `${usersUrl}/children/${year}/${companyId}/${accountId}?supplyChainId=${supplyChainId}`,
      }),
      providesTags: ['User'],
    }),

    createUser: build.mutation<UserAccount, UnsavedUserAccount>({
      query: (body: UnsavedUserAccount) => {
        return {
          url: `${usersUrl}`,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['User'],
    }),
    editMyAccount: build.mutation<UserAccount, EditUserRequest>({
      query: (data: EditUserRequest) => {
        return {
          url: `${usersUrl}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    editChildUser: build.mutation<any, EditChildUserData>({
      query: (data: EditChildUserData) => {
        const { id, ...rest } = data;
        return {
          url: `${usersUrl}/child/${id}`,
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['User'],
    }),
    activateAccount: build.mutation<
      UserAccount,
      { accountId: number; companyId: number }
    >({
      query: ({ accountId, companyId }) => {
        return {
          url: `${usersUrl}/activate/${companyId}/${accountId}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['User'],
    }),
    toggleUserStatus: build.mutation<
      UserAccount,
      { companyId: number; parentCompanyId: number; accountId: number }
    >({
      query: ({ companyId, parentCompanyId, accountId }) => {
        return {
          url: `${usersUrl}/${companyId}/${parentCompanyId}/${accountId}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['User'],
    }),
    deleteUser: build.mutation<{ email: string }, DeleteUserRequest>({
      query: ({ companyId, accountId, loggedInUserCompanyId }) => {
        return {
          url: `${usersUrl}/${accountId}/${companyId}/${loggedInUserCompanyId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User'],
    }),
    adminResetUserPassword: build.mutation<
      { userEmail: string; message: string },
      ResetPasswordRequest
    >({
      query: (body) => {
        return {
          url: `${usersUrl}/adminResetUserPassword`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useActivateAccountMutation,
  useLazyGetUserDataWithPermissionsForSelectedCompanyQuery,
  useGetUserDataWithPermissionsForSelectedCompanyQuery,
  useGetCurrentUserChildrenQuery,
  useCreateUserMutation,
  useEditMyAccountMutation,
  useDeleteUserMutation,
  useToggleUserStatusMutation,
  useEditChildUserMutation,
  useAdminResetUserPasswordMutation,
} = userApiEndpoints;
